namespace CoreConstants {
  export const ComfyTokenCacheDurationTimeInMilS = 7 * 60 * 60 * 24 * 1000; // 1 week
  export const S3CacheDurationTimeInMilS = 60 * 60 * 24 * 1000; // 24 hours
  export const S3PrivateCacheDurationTimeInMilS = 60 * 60 * 1 * 1000; // 1 hour
  export const fechUserByIDCacheDurationTimeInMilS = 30 * 1000; // 30 seconds
  export const fechPublicTasksCacheDurationTimeInMilS = 3 * 60 * 1000; // 60 seconds
  export const fetchTasksCacheDurationTimeInMilS = 5 * 60 * 1000; // 5 minutes
  export const fetchModelPresetByPresetIDCacheDurationTimeInMilS =
    5 * 60 * 1000; // 5 minutes
  export const fetchModelPresetByModelIDCacheDurationTimeInMilS = 5 * 60 * 1000; // 5 minutes
  export const fetchModelPresetByUserIDCacheDurationTimeInMilS = 5 * 60 * 1000; // 5 minutes
  export const fetchCurrentUserCacheDurationTimeInMilS = 1 * 60 * 1000; // 1 minute
  export const fetchSocialManPostsCacheDurationTimeInMilS = 5 * 60 * 1000; // 5 minutes
  export const MIN_CREDIT_CASHOUT_AMOUNT = 10000;
  export const CREDIT_TO_USD_RATION = 1 / 1000;
  export const PRESET_TAX = 30 / 100;

  export const SubscriptionsTiersRewards = {
    SUBSCRIPTION_5000: 5500,
    SUBSCRIPTION_15000: 18000,
    SUBSCRIPTION_100000: 120000,
  };

  export const SubscriptionsTiersCostUSDCents = {
    SUBSCRIPTION_5000: 500,
    SUBSCRIPTION_15000: 1500,
    SUBSCRIPTION_100000: 10000,
  };

  export const CreditsTiersRewards = {
    Credits_1000: 1000,
    Credits_7000: 7000,
    Credits_20000: 20000,
  };
  export const CreditsTiersCostUSDCents = {
    Credits_1000: 100,
    Credits_7000: 700,
    Credits_20000: 2000,
  };
}

export default CoreConstants;
