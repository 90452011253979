import { ReactElement } from "react";
import {
  LocalReactionType,
  TaskExecutionData,
  TaskExecutionOutput,
  TaskResultStatus,
} from "./modeltypings";

export type PagedTaskResults = {
  tasks: TaskExecutionCardProps[];
  nextToken?: string;
};

export type TasksWorkspaceContainerProps = {
  id: string;
  name: string;
  description: string;
  userID: string;
  tasks: [TaskExecutionCardProps];
  createdAt: string;
};

export type TaskExecutionCardProps = {
  ownerID: string;
  taskId: string;
  workspaceID: string;
  modelID: string;
  status: TaskResultStatus;
  taskExecutionData: TaskExecutionData;
  taskExecutionOutput: TaskExecutionOutput;
  reactionData: { [key: string]: number };
};
export type BaseModalContainerInput = {
  title?: string;
  additionalTitleStyle?: string;
  additionalContainerStyle?: string;
  dismissButtonText?: string;
  modalContent: ReactElement;
  onModalClose?: () => void;
  onModalDismiss?: () => void;
};
export type ConfirmModalContainerInput = {
  title?: string;
  additionalTitleStyle?: string;
  additionalContainerStyle?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  modalContent: ReactElement;
  onModalConfirm?: () => void;
  onModalCancel?: () => void;
  onModalDismiss?: (didConfirm: boolean) => void;
};
export type OptionsData = {
  name?: string;
  value?: string;
  description?: string;
};
export type CoolModalInput = {
  title: string;
  description?: string;
  paragraphText?: string;
  dismissButtonText?: string;
  listItemsHeader?: string;
  listItems?: string[];
  onModalOpen?: () => void;
  onModalClose?: () => void;
  onModalDismiss?: () => void;
};
export enum InternalCreditsPriceID {
  Credits_1000 = "Credits_1000",
  Credits_7000 = "Credits_7000",
  Credits_20000 = "Credits_20000",
}

export enum InternalSubscriptionPriceID {
  Subscription_5000 = "Subscription_5000",
  Subscription_15000 = "Subscription_15000",
  Subscription_100000 = "Subscription_100000",
}

export type BasePricingBlockProps = {
  cost: number;
  name: string;
  badgeText: string;
  credit: number;
  themeColor: string;
  bulletPoints: string[];
  CTA: string;
  internalPriceID: InternalCreditsPriceID | InternalSubscriptionPriceID;
  disabled?: boolean;
};
export enum FileType {
  Image = 1 << 0,
  Audio = 1 << 1,
  Video = 1 << 2,
  Text = 1 << 3,
}
export enum InputConstraintType {
  MAX_STRING_LENGTH = "MAX_STRING_LENGTH",
  MAX_TOKENS = "MAX_TOKENS",
  REGEX = "REGEX",
  MAX_VALUE = "MAX_VALUE",
  MIN_VALUE = "MIN_VALUE",
  IMAGE_RATIO = "IMAGE_RATIO",
  IMAGE_WIDTH = "IMAGE_WIDTH",
  IMAGE_HEIGHT = "IMAGE_HEIGHT",
  MAX_FILE_SIZE_MB = "MAX_FILE_SIZE_MB",
}
export type InputConstraint = {
  type: InputConstraintType;
  value: any;
  resolutionMessage?: string;
};
