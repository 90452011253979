"use client";
import { raleway } from "@/utils/Constants";
import Link from "next/link";
import { usePathname } from "next/navigation";
// import { useSearchParams } from "next/navigation";
type LogoProps = {
  textSize: number;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export default function Logo({
  textSize = 3,
  isLoading = false,
  isDisabled = false,
}: LogoProps) {
  // const searchParams = useSearchParams();
  const pathname = usePathname();
  const textSizeStyle =
    textSize <= 1
      ? "text-xl"
      : textSize == 2
      ? "text-2xl"
      : textSize == 3
      ? "text-2xl"
      : textSize == 4
      ? "text-2xl"
      : textSize == 5
      ? "text-2xl"
      : "text-6xl";
  const isSocialMan = pathname.includes("socialman");
  const logoString = isSocialMan ? "SocialMan" : "MLTask";
  function getColorSplitIndex() {
    if (isSocialMan) {
      return 6;
    }
    return 2;
  }
  function renderLogoString() {
    return (
      <div
        className={`${
          isLoading ? "animate-wobble-scale " : ""
        } flex flex-row text-primary`}
      >
        {Array.from(logoString).map((x, index) => {
          const pClassName = index < getColorSplitIndex() ? "text-accent" : "";
          const divClass = `animate-pulse `;
          return (
            <div
              className={isLoading ? divClass : ""}
              style={{ animationDelay: `${index * 500}ms` }}
              key={index}
            >
              <p className={pClassName}>{x}</p>
            </div>
          );
        })}
      </div>
    );
  }
  var destination = isSocialMan ? "/socialman" : "/";
  return (
    <Link
      className={`${isDisabled == true ? "pointer-events-none" : ""}`}
      href={destination}
    >
      <div
        className={`flex ${textSizeStyle} mx-auto flex flex-row justify-center ${raleway.className} `}
      >
        {renderLogoString()}
      </div>
    </Link>
  );
}
