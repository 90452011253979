export type MLTasksData = {
  entries: MLTaskPropertyEntryData[];
};

export type MLTaskPropertyEntryData = {
  id: string;
  name: string;
  isPublic: boolean;
  category: string;
  modelsInfo: ModelsInfo[];
};

export enum ClientTokenizerModel {
  davinci_002 = "davinci_002",
  babbage_002 = "babbage_002",
  text_davinci_003 = "text_davinci_003",
  text_davinci_002 = "text_davinci_002",
  text_davinci_001 = "text_davinci_001",
  text_curie_001 = "text_curie_001",
  text_babbage_001 = "text_babbage_001",
  text_ada_001 = "text_ada_001",
  davinci = "davinci",
  curie = "curie",
  babbage = "babbage",
  ada = "ada",
  code_davinci_002 = "code_davinci_002",
  code_davinci_001 = "code_davinci_001",
  code_cushman_002 = "code_cushman_002",
  code_cushman_001 = "code_cushman_001",
  davinci_codex = "davinci_codex",
  cushman_codex = "cushman_codex",
  text_davinci_edit_001 = "text_davinci_edit_001",
  code_davinci_edit_001 = "code_davinci_edit_001",
  text_embedding_ada_002 = "text_embedding_ada_002",
  text_embedding_3_small = "text_embedding_3_small",
  text_embedding_3_large = "text_embedding_3_large",
  text_similarity_davinci_001 = "text_similarity_davinci_001",
  text_similarity_curie_001 = "text_similarity_curie_001",
  text_similarity_babbage_001 = "text_similarity_babbage_001",
  text_similarity_ada_001 = "text_similarity_ada_001",
  text_search_davinci_doc_001 = "text_search_davinci_doc_001",
  text_search_curie_doc_001 = "text_search_curie_doc_001",
  text_search_babbage_doc_001 = "text_search_babbage_doc_001",
  text_search_ada_doc_001 = "text_search_ada_doc_001",
  code_search_babbage_code_001 = "code_search_babbage_code_001",
  code_search_ada_code_001 = "code_search_ada_code_001",
  gpt2 = "gpt2",
  gpt_3__5_turbo = "gpt_3__5_turbo",
  gpt_35_turbo = "gpt_35_turbo",
  gpt_3__5_turbo_0301 = "gpt_3__5_turbo_0301",
  gpt_3__5_turbo_0613 = "gpt_3__5_turbo_0613",
  gpt_3__5_turbo_1106 = "gpt_3__5_turbo_1106",
  gpt_3__5_turbo_0125 = "gpt_3__5_turbo_0125",
  gpt_3__5_turbo_16k = "gpt_3__5_turbo_16k",
  gpt_3__5_turbo_16k_0613 = "gpt_3__5_turbo_16k_0613",
  gpt_3__5_turbo_instruct = "gpt_3__5_turbo_instruct",
  gpt_3__5_turbo_instruct_0914 = "gpt_3__5_turbo_instruct_0914",
  gpt_4 = "gpt_4",
  gpt_4_0314 = "gpt_4_0314",
  gpt_4_0613 = "gpt_4_0613",
  gpt_4_32k = "gpt_4_32k",
  gpt_4_32k_0314 = "gpt_4_32k_0314",
  gpt_4_32k_0613 = "gpt_4_32k_0613",
  gpt_4_turbo = "gpt_4_turbo",
  gpt_4_turbo_2024_04_09 = "gpt_4_turbo_2024_04_09",
  gpt_4_turbo_preview = "gpt_4_turbo_preview",
  gpt_4_1106_preview = "gpt_4_1106_preview",
  gpt_4_0125_preview = "gpt_4_0125_preview",
  gpt_4_vision_preview = "gpt_4_vision_preview",
  gpt_4o = "gpt_4o",
  gpt_4o_2024_05_13 = "gpt_4o_2024_05_13",
  gpt_4o_2024_08_06 = "gpt_4o_2024_08_06",
  gpt_4o_mini_2024_07_18 = "gpt_4o_mini_2024_07_18",
  gpt_4o_mini = "gpt_4o_mini",
}

export type LocalModelInputEntryCost = {
  tokenizerModel?: ClientTokenizerModel;
  costPerToken?: number;
  costPerCharacter?: number;
  costPerWord?: number;
  costPerValue?: number;
  costInterpolateMin?: number;
  costInterpolateMax?: number;
  costPerFileSize?: number;
  costPerFileDuration?: number;
  flatFee?: number;
  isMultiplier?: boolean;
};

export type ModelsInfo = {
  id: string;
  taskID: string;
  name: string;
  isPublic: boolean;
  inputs: ModelInput;
  outputs: ModelOutput;
  // TODO add model info card/ description
  // TODO add model output sample
};

export type ModelInput = {
  entries: ModelInputEntry[];
};

export enum ModelInputOutputEntryType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  NUMBER_FLOAT = "NUMBER_FLOAT",
  NUMBER_INT = "NUMBER_INT",
  URL_IMAGE = "URL_IMAGE",
  URL_AUDIO = "URL_AUDIO",
  URL_VIDEO = "URL_VIDEO",
}
export enum OptionType {
  TOGGLE_GROUP_SINGLE = "TOGGLE_GROUP_SINGLE",
  DROP_DOWN = "DROP_DOWN",
  STRICT_SLIDER = "STRICT_SLIDER",
  RANGE = "RANGE",
}

export enum ModelInputConstraintType {
  MAX_STRING_LENGTH = "MAX_STRING_LENGTH",
  MAX_TOKENS = "MAX_TOKENS",
  REGEX = "REGEX",
  MAX_VALUE = "MAX_VALUE",
  MIN_VALUE = "MIN_VALUE",
  IMAGE_RATIO = "IMAGE_RATIO",
  IMAGE_WIDTH = "IMAGE_WIDTH",
  IMAGE_HEIGHT = "IMAGE_HEIGHT",
  MAX_FILE_SIZE_MB = "MAX_FILE_SIZE_MB",
}
export enum LocalTaskPrivacyLevels {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum LocalReactionType {
  HEART = "HEART",
  CUTE = "CUTE",
  FUNNY = "FUNNY",
  WOW = "WOW",
  SAD = "SAD",
  ANGRY = "ANGRY",
  SCARY = "SCARY",
  POOP = "POOP",
  GROSS = "GROSS",
}

// export type OptionType =
//   | "TOGGLE_GROUP_SINGLE"
//   | "DROP_DOWN"
//   | "STRICT_SLIDER"
//   | "RANGE";
// export type ModelInputConstraintType =
//   | "MAX_STRING_LENGTH"
//   | "MAX_TOKENS"
//   | "REGEX"
//   | "MAX_VALUE"
//   | "MIN_VALUE"
//   | "IMAGE_RATIO"
//   | "IMAGE_WIDTH"
//   | "IMAGE_HEIGHT"
//   | "MAX_FILE_SIZE_MB";

// export type ModelInputOutputEntryType =
//   | "TEXT"
//   | "NUMBER"
//   | "NUMBER_INT"
//   | "NUMBER_FLOAT"
//   | "URL_IMAGE"
//   | "URL_AUDIO"
//   | "URL_VIDEO";
//             { type: "minImageWidth", value: 4 },
//             { type: "minImageHeight", value: 4 },
//             { type: "maxImageWidth", value: 4 },
//             { type: "maxImageHeight", value: 4 },

export type ModelInputConstraint = {
  type: ModelInputConstraintType;
  value: any;
  resolutionMessage?: string;
};

export type ModelInputEntry = {
  type: ModelInputOutputEntryType;
  name: string;
  cost: LocalModelInputEntryCost;
  defaultValue?: any;
  options?: ModelInputOptionsDataInput[];
  optionType?: OptionType;
  optionStep?: number;
  description: string;
  examples: string[];
  constraints?: ModelInputConstraint[];
  isRequired: boolean;
  // TODO add model output sample that shows how this affects the output
};

export type ModelOutput = {
  entries: ModelOutputEntry[];
};

export type ModelInputOptionsDataInput = {
  name?: string;
  value?: string;
  description?: string;
  cost?: number;
};

export type ModelOutputEntry = {
  type: ModelInputOutputEntryType;
  name: string;
};

export type TaskExecutionData = {
  modelID: string;
  task: string;
  model: string;
  privacyLevel: LocalTaskPrivacyLevels;
  inputs: TaskExecutionInput;
  presetUserInput?: LocalModelInputPresetInputUserValue;
};

export type InputMetaData = {
  FILE_NAME?: string;
  FILE_TYPE?: string;
  FILE_SIZE_KB?: number;
  DURATION?: number;
  WIDTH?: number;
  HEIGHT?: number;
  STRING_LENGTH?: number;
  TOKENS_COUNT?: number;
};

export type TaskExecutionInputData = {
  name: string;
  value: any;
  type: ModelInputOutputEntryType;
  metaData?: InputMetaData;
};

export type TaskExecutionInput = {
  [key: string]: TaskExecutionInputData;
};
// entry.createdAt
export type TaskExecutionOutputEntry = {
  type: ModelInputOutputEntryType;
  name: string;
  value: any;
};

export type CloudAccessLevels = "guest" | "protected" | "private";

export type TaskResultStatus =
  | "DRAFT"
  | "ENQUEUED"
  | "EXECUTING"
  | "FINISHED"
  | "FAILED";
export type TaskExecutionOutput = {
  // taskId: string;
  // taskExecutionData: TaskExecutionData;
  misc?: { [key: string]: any };
  outputs: TaskExecutionOutputEntry[];
  message: string;
  creditsUsed: number;
  createdAt: string;
};

export enum LocalTwitterVerifiedType {
  UNVERIFIED = "none",
  BLUE = "blue",
  BUSINESS = "business",
  GOVERNMENT = "government",
}

export enum LocalSubscriptionPlan {
  NO_PLAN = "NO_PLAN",
  SUBSCRIPTION_5000 = "SUBSCRIPTION_5000",
  SUBSCRIPTION_15000 = "SUBSCRIPTION_15000",
  SUBSCRIPTION_100000 = "SUBSCRIPTION_100000",
}

export type LocalUser = {
  id: string;
  email: string;
  name?: string;
  username?: string;
  currentPlan: LocalSubscriptionPlan;
  picture?: string;
  stripeID: string;
  stripeConnectAccountID?: string;
  stripeConnectAccountSetupComplete: boolean;
  credits: number;
  redeemableCredits: number;
  createdPresetCount: number;
  completedTasksCount: number;
  tiktok?: {
    refreshTokenExpirationDate: number;
    avatarURL?: string;
    username?: string;
    privacyLevelOptions: string[];
    commentDisabled: boolean;
    duetDisabled: boolean;
    stitchDisabled: boolean;
    maxVideoPostDuration_sec: number;
  };
  linkedin?: {
    name: string;
    picture: string;
    tokenExpirationDate: number;
  };
  google?: {
    name?: string;
    username: string;
    picture?: string;
    channels: {
      id: string;
      customUrl: string;
      title: string;
      description: string;
      thumbnail: string;
    }[];
  };
  twitter?: {
    id?: string;
    username?: string;
    profilePictureURL?: string;
    verifiedType?: LocalTwitterVerifiedType;
  };
  pinterest?: {
    refreshTokenExpirationDate: number;
    profileImage?: string;
    username?: string;
    boardsInfo: {
      id: string;
      name: string;
      pinCount: number;
      followerCount: number;
      imageCoverUrl: string;
      createdAt: string;
      description: string;
      privacy: string;
    }[];
  };
  facebook?: {
    id: string;
    name: string;
    picture: string;
    tokenExpiresOn: number;
    pages: {
      id?: string;
      name?: string;
      picture?: string;
      category?: string;
      tokenExpiresOn: number;
    }[];
  };
  instagram?: {
    accounts: {
      id: string;
      igID: string;
      biography: string;
      name: string;
      username: string;
      profilePictureURL: string;
      followsCount: number;
      followersCount: number;
      mediaCount: number;
      tokenExpiresOn: number;
    }[];
  };
};

export type PagedLocalModelInputPresetResults = {
  presets: LocalModelInputPreset[];
  nextToken?: string;
};

export type LocalInputPresetEntryType = "PRESET_INPUT" | "USER_INPUT";
export type LocalModelInputPresetEntryDataInput = {
  entryType: LocalInputPresetEntryType;
  parameterName?: string;
  val?: string;
  description?: string;
  presetOrder: number;
  metaData?: InputMetaData;
};

export type LocalModelInputPresetEntryInput = {
  modelInputName?: string | null;
  modelInputType?: ModelInputOutputEntryType | null;
  presetEntryDatas: LocalModelInputPresetEntryDataInput[];
};

export type LocalModelInputPresetEntryInputUserValueEntryData = {
  parameterName: string;
  val: string;
  presetOrder: number;
};

export type LocalModelInputPresetEntryInputUserValue = {
  modelInputName: string;
  presetEntryDatas: LocalModelInputPresetEntryInputUserValueEntryData[];
};
export type LocalModelInputPresetInputUserValue = {
  presetID?: string;
  presetName?: string;
  presetCreatorName?: string;
  presetCreatorUID?: string;
  presetValues?: LocalModelInputPresetEntryInputUserValue[];
};
export type LocalModelInputPresetVersionHistory = {
  versionName: string;
  cost: number;
  entries: LocalModelInputPresetEntryInput[];
};

export type LocalModelInputPreset = {
  presetID: string;
  presetName: string;
  description: string;
  isPublic: boolean;
  isOpenSource: boolean;
  cost: number;
  versionName: string;
  versionHistory: LocalModelInputPresetVersionHistory[];
  entries: LocalModelInputPresetEntryInput[];
  modelsID: string;
  userID: string;
  username: string;
  taskName: string;
  modelName: string;
  usage: number;
  createdAt: string;
};

export type StripeInvoice = {
  amount: number;
  createdAt: string;
  currency: string;
  name: string;
  email: string;
  view_link: string;
  download_link: string;
};

export type StripePayoutHistoryItem = {
  amount: number;
  created: string;
  currency: string;
  description: string;
};

export type ComfyTokenData = {
  token: string;
  scopes: string[];
  createdAt: string;
  updatedAt: string;
  expirationDate: string;
  revokeDate: string;
};
